// src/AnimatedLogo.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import logo from '../avatar/av5.jpg';


const fadeInAndZoom = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%) scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: translateX(0) scale(0.75);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
`;

const AnimatedLogo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          animation: `${fadeInAndZoom} 2s ease-in-out`,
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: 200, marginRight: -10 }}
        />
         <Typography
            sx={{
                fontFamily: "'Blithe Regular', cursive",
                color: '#c1c8b3',
                fontSize: '1.4rem',
                fontWeight: 'bold',
                textAlign: 'center'
            }}
        >
            your everyday
            <Box
                component="span"
                sx={{
                    display: 'block',
                    fontFamily: "'Blithe Regular', cursive",
                    color: '#c1c8b3', 
                    fontSize: '1.4rem',
                    mt: 1
                }}
            >
                elegance...
            </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default AnimatedLogo;
