import React from 'react';
import { Box, CardMedia, Container, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo from '../avatar/av5.jpg'; // Path to your logo image
import backgroundImage from '../avatar/av7.jpg'; // Path to your background image
import productImage from '../avatar/av3.jpg'; // Path to your product image

const useStyles = makeStyles((theme) => ({
    background: {
        minHeight: '100vh', // Full viewport height
        width: '100%', // Full width
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    root: {
        maxWidth: 1200,
        width: '100%',
        margin: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        color: 'white', // Ensure text is readable on the background
    },
}));

const TASuccess = () => {
    const classes = useStyles();

    const orderId = '1234567890'; // Example order ID
    const productName = 'Yellow Floral Delight Kurti Set';
    const totalPaid = 1255; // Example total amount paid (including shipping)

    return (
        <Box className={classes.background}>
            <Container className={classes.root}>
                {/* Header Section */}
                <Stack direction="row" alignItems="center" sx={{ mb: 4, mt: -4 }} spacing={5}>
                    <CardMedia
                        component="img"
                        image={logo}
                        alt="Brand Logo"
                        sx={{ width: 120, height: 120 }}
                    />
                    <Typography sx={{ fontFamily: "'Blithe Regular', cursive", color: '#c1c8b3', fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>
                        your everyday
                        <Box
                            component="span"
                            sx={{ display: 'block', fontFamily: "'Blithe Regular', cursive", color: '#c1c8b3', fontSize: '1.2rem', mt: 1 }}
                        >
                            elegance...
                        </Box>
                    </Typography>
                </Stack>

                {/* Main Content Section */}
                <Box sx={{ backgroundColor: '#e4eac8', borderRadius: 2, p: 4, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }} my={4}>
                    <Stack direction="row" spacing={2} alignItems="center" mb={4}>
                        {/* Product Image */}
                        <Box
                            component="img"
                            src={productImage} // Replace with actual image path
                            alt={productName}
                            sx={{ width: 100, height: 120, borderRadius: 1 }}
                        />
                        {/* Product Details */}
                        <Stack spacing={1}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: "'Playfair Display', serif", color: '#043430' }}>
                                {productName}
                            </Typography>
                            <Typography variant="body1" sx={{ fontFamily: "'Playfair Display', serif", color: '#043430' }}>
                                Total Paid (including shipping): ₹{totalPaid.toFixed(2)}
                            </Typography>
                        </Stack>
                    </Stack>

                    {/* Order ID and Success Message */}
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: "'Playfair Display', serif", mb: 2, color: '#043430' }}>
                            Order ID: {orderId}
                        </Typography>
                        <Typography variant="body1" sx={{ fontFamily: "'Playfair Display', serif", color: '#043430' }}>
                            Payment has been completed successfully and you'll receive updates on your WhatsApp number.
                        </Typography>
                    </Box>
                </Box>

                {/* Footer Section */}
                <Box
                    component="footer"
                    sx={{
                        textAlign: 'center',
                        mt: 34,
                        py: 2,
                        borderTop: '1px solid #e4eac8',
                        color: '#e4eac8',
                        fontSize: '0.875rem',
                        fontFamily: "'Playfair Display', serif",
                    }}
                >
                    © 2024 Thalir. All rights reserved.
                </Box>
            </Container>
        </Box>
    );
};

export default TASuccess;
