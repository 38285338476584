import React, { useState } from 'react';
import {
    Box,
    Button,
    CardMedia,
    Container,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails, Dialog, DialogContent } from '@mui/material';
import { ExpandMore, ShoppingBag } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import axios from 'axios'; // Add Axios for API requests

// Import your images
import image1 from '../avatar/av3.jpg';
import logo from '../avatar/av5.jpg'; // Replace with the path to your logo image
import backgroundImage from '../avatar/av7.jpg'; // Replace with the path to your background image

const StyledTextField = ({ label, ...props }) => {
    return (
        <TextField
    sx={{
        '& .MuiInputBase-input': {
            backgroundColor: '#e4eac8', // Set the inner input area background color
            fontFamily: "'Playfair Display', serif",
        },
        '& .MuiInputLabel-root': {
            fontFamily: "'Playfair Display', serif",
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#043430', // Border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#043430', // Border color on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#043430', // Border color on focus
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#043430', // Label color on focus
        },
    }}
    label={label}
    fullWidth
    variant="outlined"
    autoComplete="off"
    {...props} // Pass any additional props
/>

    );
};

const useStyles = makeStyles((theme) => ({
    background: {
        minHeight: '100vh', // Full viewport height
        width: '100%', // Full width
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    root: {
        maxWidth: 1200,
        width: '100%',
        margin: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        color: 'white', // Ensure text is readable on the background
    },
}));

const HDLandForm = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const [zipCodeError, setZipCodeError] = useState(false);
    const [zipCodeHelperText, setZipCodeHelperText] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [expanded, setExpanded] = useState(false);
    const productPrice = 1195; // Price per product
    const shippingPrice = 60; // Fixed shipping cost
    const productTotal = quantity * productPrice; // Product total
    const totalPrice = productTotal + shippingPrice;

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleIncrement = () => setQuantity(prev => prev + 1);
    const handleDecrement = () => {
        if (quantity > 0) setQuantity(prev => prev - 1);
    };

    const handleAccordionChange = () => {
        setExpanded((prev) => !prev);
    };

    const handleZipCodeBlur = () => {
        if (zipCode.length !== 6 && zipCode.length > 0) {
            setZipCodeError(true);
            setZipCodeHelperText('ZIP Code must be exactly 6 digits.');
        } else {
            setZipCodeError(false);
            setZipCodeHelperText('');
        }
    };

    const handleZipCodeChange = async (e) => {
        const zip = e.target.value;
        setZipCode(zip);

        if (zip.length === 6) {
            try {
                const response = await axios.get(`https://api.postalpincode.in/pincode/${zip}`);
                const data = response.data[0];

                if (data.Status === 'Success') {
                    const details = data.PostOffice[0];
                    setCity(details.District);
                    setState(details.State);
                    setCountry('India');
                    setZipCodeError(false); // Reset error
                    setZipCodeHelperText(''); // Clear helper text
                } else {
                    setZipCodeError(true);
                    setZipCodeHelperText('Invalid ZIP Code. Please try again.');
                    setCity('');
                    setState('');
                    setCountry('');
                }
            } catch (error) {
                console.error('Error fetching ZIP Code data:', error);
                setZipCodeError(true);
                setZipCodeHelperText('Could not fetch details. Please try again.');
            }
        }
    };

    const amount = 500
    const currency = "INR"
    const receipt = "3gvyg"


    const handleBuyNow = async (e) => {
        try {
            const response = await axios .post("https://localhost:5001/order", {
                amount,
                currency,
                receipt
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    
            const order = response.data;

            var options = {
                "key": "rzp_test_SGWTu2rFN8mYnS", // Enter the Key ID generated from the Dashboard
                "amount": "5000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "Thalir", //your business name
                "description": "Test Transaction",
                "image": "https://example.com/your_logo",
                "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function (response2){
                    const body ={
                        ...response2,
                    };
                    const validateReponse = await fetch("https://localhost:5001/order/validate",{
                        method: "POST",
                        body: JSON.stringify(body),
                        headers:{
                            "Content-Type": "application/json"
                        }

                    })
                    const jsonRes = await validateReponse.json();
                    console.log(jsonRes);
                    if (jsonRes.status == 200) {
                        navigate('/success')
                    }
                    
                    
                },
                "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                    "name": "Gaurav Kumar", //your customer's name
                    "email": "gaurav.kumar@example.com", 
                    "contact": "9000090000"  //Provide the customer's phone number for better conversion rates 
                },
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on('payment.failed', function (response){
                    alert(response.error.code);
                    alert(response.error.description);
                    alert(response.error.source);
                    alert(response.error.step);
                    alert(response.error.reason);
                    alert(response.error.metadata.order_id);
                    alert(response.error.metadata.payment_id);
            });
            rzp1.open();
            e.preventDefault();
            console.log(order);
        } catch (error) {
            console.error("There was an error making the request:", error);
        }
    };

    const handleBack = () => {
        navigate('/landing');
    };


    return (
        <Box className={classes.background}>
            <Container className={classes.root}>
                {/* Header Section */}
                <Stack direction="row" alignItems="center" sx={{ mb: 4, mt: -8 }} spacing={5}>
                    <CardMedia
                        component="img"
                        image={logo}
                        alt="Brand Logo"
                        sx={{ width: 120, height: 120 }}
                    />
                    <Typography sx={{ fontFamily: "'Blithe Regular', cursive", color: '#c1c8b3', fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>
                        your everyday
                        <Box
                            component="span"
                            sx={{ display: 'block', fontFamily: "'Blithe Regular', cursive", color: '#c1c8b3', fontSize: '1.2rem', mt: 1 }}
                        >
                            elegance...
                        </Box>
                    </Typography>
                </Stack>

                <Accordion
                    expanded={expanded}
                    onChange={handleAccordionChange}
                    sx={{
                        backgroundColor: '#e4eac8',
                        borderRadius: 2,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        my: 2,
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="bag-content"
                        id="bag-header"
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <ShoppingBag fontSize="medium" />
                            <Typography variant="h6" sx={{ fontFamily: "'Playfair Display', serif", fontWeight: 'bold' }}>
                                Bag
                            </Typography>
                        </Stack>
                        {!expanded && (
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: "'Playfair Display', serif",
                                    fontWeight: 'bold',
                                    ml: 'auto',
                                    mt: .7
                                }}
                            >
                                Total: ₹{totalPrice.toFixed(2)}
                            </Typography>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {/* Product Image */}
                            <Box
                                component="img"
                                src={image1} // Replace with actual image path
                                alt="Yellow Floral Delight Kurti Set"
                                sx={{
                                    width: 100,
                                    height: 120,
                                    borderRadius: 1,
                                    alignSelf: "flex-start",
                                    transform: "translateY(2px)",
                                    cursor: "pointer",
                                }}
                                onClick={handleOpen}
                            />
                            {/* Popup Dialog */}
                            <Dialog open={open} onClose={handleClose} maxWidth="md">
                                <DialogContent>
                                    <Box
                                        component="img"
                                        src="path_to_image"
                                        alt="Yellow Floral Delight Kurti Set"
                                        sx={{
                                            width: "100%",
                                            borderRadius: 1,
                                        }}
                                    />
                                </DialogContent>
                            </Dialog>
                            {/* Product Details */}
                            <Stack spacing={1} flex={1}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "'Playfair Display', serif" }}>
                                    Yellow Floral Delight Kurti Set
                                </Typography>
                                <Typography variant="body2" sx={{ fontFamily: "'Playfair Display', serif" }}>
                                    <b>Brand:</b> Thalir
                                </Typography>
                                <Typography variant="body2" sx={{ fontFamily: "'Playfair Display', serif" }}>
                                    <b>Price:</b> ₹{productPrice.toFixed(2)}
                                </Typography>
                                <Typography variant="body2" sx={{ fontFamily: "'Playfair Display', serif" }}>
                                    <b>Color:</b> Yellow
                                </Typography>
                                <Typography variant="body2" sx={{ fontFamily: "'Playfair Display', serif" }}>
                                    <b>Size:</b> S
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ display: 'flex', alignItems: 'center', fontFamily: "'Playfair Display', serif" }}
                                >
                                    <b>Quantity:</b>&nbsp;
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 90,
                                            height: 40,
                                            backgroundColor: '#043430',
                                            borderRadius: '50px',
                                            color: '#e4eac8',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            gap: 1,
                                            ml: 2,
                                        }}
                                    >
                                        <Button
                                            onClick={handleDecrement}
                                            sx={{
                                                backgroundColor: 'transparent',
                                                color: '#e4eac8',
                                                fontSize: '1.5rem',
                                                minWidth: 'auto',
                                                padding: 0,
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                                mr: 1,
                                            }}
                                            disabled={quantity <= 1}
                                        >
                                            −
                                        </Button>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                my: 2,
                                                fontFamily: "'Playfair Display', serif",
                                                fontWeight: 'bold',
                                                color: '#e4eac8',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {quantity}
                                        </Typography>
                                        <Button
                                            onClick={handleIncrement}
                                            sx={{
                                                backgroundColor: 'transparent',
                                                color: '#e4eac8',
                                                fontSize: '1.5rem',
                                                minWidth: 'auto',
                                                padding: 0,
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                                ml: 1,
                                            }}
                                            disabled={quantity >= 10}
                                        >
                                            +
                                        </Button>
                                    </Box>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider sx={{ my: 2 }} />
                        {expanded && (
                            <Stack spacing={1} sx={{ mt: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: "'Playfair Display', serif",
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                    }}
                                >
                                    Product Total: ₹{productTotal.toFixed(2)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: "'Playfair Display', serif",
                                        textAlign: 'right',
                                    }}
                                >
                                    Shipping: ₹{shippingPrice.toFixed(2)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: "'Playfair Display', serif",
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                    }}
                                >
                                    Total: ₹{totalPrice.toFixed(2)}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontFamily: "'Playfair Display', serif",
                                        textAlign: 'right',
                                        display: 'block', // Ensures it appears as a separate line
                                        color: 'gray',
                                    }}
                                >
                                    * inclusive of all taxes
                                </Typography>
                            </Stack>
                        )}
                    </AccordionDetails>
                </Accordion>




                {/* Form Section */}
                <Container
                    maxWidth="md"
                    sx={{
                        backgroundColor: '#e4eac8',
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton onClick={handleBack} color="#b7ce58">
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" gutterBottom sx={{ fontFamily: "'Playfair Display', serif", color: '#043430' }}>
                            Shipping Details
                        </Typography>
                    </Stack>
                    <Divider sx={{ marginBottom: 3 }} />
                    <Stack spacing={3}>
                        {/* Personal Information */}
                        <Stack spacing={2}>
                            <StyledTextField label="Name" />
                            <StyledTextField label="Email" />
                            <StyledTextField label="Phone" />
                        </Stack>

                        {/* Address Section */}
                        <Typography variant="h6" gutterBottom sx={{ fontFamily: "'Playfair Display', serif", color: '#043430' }} >
                            Address
                        </Typography>
                        <Grid container spacing={1} sx={{ marginTop: 0 }}>
                            <Grid item xs={12}>
                                <StyledTextField label="Main Address" />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTextField label="Street" />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTextField label="Street 2" />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTextField
                                    label="ZIP Code"
                                    value={zipCode}
                                    onChange={handleZipCodeChange}
                                    onBlur={handleZipCodeBlur}
                                    error={zipCodeError}
                                    helperText={zipCodeHelperText}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTextField
                                    label="City"
                                    value={city}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTextField
                                    label="State"
                                    value={state}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTextField
                                    label="Country"
                                    value={country}
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        {/* Proceed to Payment Button */}
                        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={handleBuyNow}
                                sx={{
                                    width: '100%',
                                    padding: '12px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    backgroundColor: '#b7ce58',
                                    fontFamily: "'Playfair Display', serif",
                                    color: '#043430',
                                }}
                            >
                                Proceed to Payment
                            </Button>
                        </Box>
                    </Stack>
                </Container>
                <Box
                    component="footer"
                    sx={{
                        textAlign: 'center',
                        mt: 4,
                        py: 2,
                        borderTop: '1px solid #e4eac8',
                        color: '#e4eac8',
                        fontSize: '0.875rem',
                        fontFamily: "'Playfair Display', serif",
                    }}
                >
                    © 2024 Thalir. All rights reserved.
                </Box>
            </Container>
        </Box>
    );
};

export default HDLandForm;
