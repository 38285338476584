import React from 'react';
import { Typography, Container } from '@mui/material';

function Contact() {
  return (
    <Container>
      <Typography variant="h2" component="h1">
        Contact Page
      </Typography>
      <Typography variant="body1">
        This is the contact page.
      </Typography>
    </Container>
  );
}

export default Contact;
