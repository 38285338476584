import React from 'react';
import { Typography, Container } from '@mui/material';

function About() {
  return (
    <Container>
      <Typography variant="h2" component="h1">
        About Page
      </Typography>
      <Typography variant="body1">
        This is the about page.
      </Typography>
    </Container>
  );
}

export default About;
