import * as React from 'react';
import {
    Box,
    Button,
    Card,
    CardMedia,
    Container,
    Stack,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    Divider,
    Dialog,
    DialogContent,
    IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { ShoppingCart as ShoppingCartIcon, Star as StarIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Info as InfoIcon } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import styled, { keyframes } from 'styled-components';

// Import your images
import image1 from '../avatar/av3.jpg';
import image2 from '../avatar/av4.jpg';
import logo from '../avatar/av5.jpg'; // Replace with the path to your logo image
import backgroundImage from '../avatar/av7.jpg'; // Replace with the path to your background image
import sizeChart from '../avatar/av8.jpg'; // Replace with the path to your background image
import returnImage from '../avatar/return.jpg'; // Replace with the path to your background image
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import AnimatedLogo from './AnimatedLogo';

const images = [image1, image2, image1, image2, image1, image2];

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const AnimatedLogoWrapper = styled.div`
  animation: ${({ fade }) => (fade ? fadeOut : fadeIn)} 1s ease-in-out;
  opacity: ${({ fade }) => (fade ? 0 : 1)};
`;

const ContainerWrapper = styled(Container)`
  animation: ${fadeIn} 1s ease-in-out;
  opacity: 1;
`;

// Custom styles
const useStyles = makeStyles((theme) => ({
    background: {
        minHeight: '100vh', // Full viewport height
        width: '100%', // Full width
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    root: {
        maxWidth: 1200,
        width: '100%',
        margin: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        color: 'white', // Ensure text is readable on the background
    },
    carouselContainer: {
        maxWidth: '600px',
        margin: '0 auto',
        overflow: 'hidden',
        position: 'relative',
        height: '400px',
        marginBottom: 10,
    },
    carouselCard: {
        borderRadius: theme.spacing(2),
        overflow: 'hidden',
        height: '100%',
    },
    carouselImage: {
        objectFit: 'cover',
        objectPosition: 'center',
        width: '100%',
        height: '100%',
    },
    navigationButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        borderRadius: '50%',
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
    },
    prevButton: {
        left: 5,
    },
    nextButton: {
        right: 5,
    },
    productDetails: {
        textAlign: 'left',
        marginBottom: theme.spacing(4),
    },
    price: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '1.5rem',
        marginBottom: theme.spacing(2),
    },
    description: {
        marginTop: theme.spacing(4),
    },
}));

    

const HDNewPricing = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [animate, setAnimate] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setFadeOut(true);
          setTimeout(() => {
            setAnimate(false);
          }, 1000); // Match this duration with the CSS transition duration
        }, 3000); // Change 3000 to the desired number of milliseconds
    
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    const [color, setColor] = React.useState('yellow');
    const [quantity, setQuantity] = React.useState(1);
    const [size, setSize] = React.useState('M');
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const bottomImageRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openReturn, setOpenReturn] = useState(false);

    const handleClickOpenReturn = () => {
        setOpenReturn(true);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseReturn = () => {
        setOpenReturn(false);
    };

    const handleBuyNow = () => {
        navigate('/checkout');
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    //   useEffect(() => {
    //     // Automatically scroll the bottom row to highlight the selected image
    //     if (bottomImageRef.current) {
    //       const selectedImage = bottomImageRef.current.querySelectorAll('img');
    //       selectedImage[currentIndex].scrollIntoView({ behavior: 'smooth', inline: 'center' });
    //     }
    //   }, [currentIndex]);

    return (
        <Box className={classes.background}>
            {animate ?
            <AnimatedLogoWrapper fade={fadeOut}>
                <AnimatedLogo />
            </AnimatedLogoWrapper>
            :
            <Container className={classes.root}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mb: 4, mt: -8, mb: 1 }}
                    spacing={5}
                >
                    <CardMedia
                        component="img"
                        image={logo}
                        alt="Brand Logo"
                        sx={{ width: 120, height: 120 }}
                    />
                    <Typography
                        sx={{
                            fontFamily: "'Blithe Regular', cursive",
                            color: '#c1c8b3', 
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}
                    >
                        your everyday
                        <Box
                            component="span"
                            sx={{
                                display: 'block',
                                fontFamily: "'Blithe Regular', cursive",
                                color: '#c1c8b3',
                                fontSize: '1.2rem',
                                mt: 1
                            }}
                        >
                            elegance...
                        </Box>
                    </Typography>


                </Stack>

                <Box className="carouselContainer">
                    {/* Top Section - Single Image with Navigation */}
                    <Box className={classes.carouselContainer} sx={{ mb: 2 }}>
                        <Card className={classes.carouselCard}>
                            <CardMedia
                                component="img"
                                image={images[currentIndex]}
                                alt={`Slide ${currentIndex + 1}`}
                                className={classes.carouselImage}
                            />
                        </Card>
                        <Button
                            className="navigationButton prevButton"
                            onClick={handlePrev}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '10px',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            &lt;
                        </Button>
                        <Button
                            className="navigationButton nextButton"
                            onClick={handleNext}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            &gt;
                        </Button>
                    </Box>

                    {/* Bottom Section - Row of Images */}
                    <Box ref={bottomImageRef} className="carouselBottomSection" sx={{ overflowX: 'auto' }}>
                        <Stack direction="row" spacing={1} sx={{ display: 'flex' }}>
                            {images.map((image, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        border: currentIndex === index ? '2px solid blue' : 'none',
                                        transition: 'border 0.3s ease',
                                    }}
                                >
                                    <Card
                                        sx={{
                                            width: 100,
                                            height: 100,
                                            border: currentIndex === index ? '2px solid blue' : 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setCurrentIndex(index)}
                                    >
                                        <CardMedia
                                            component="img"
                                            image={image}
                                            alt={`Thumbnail ${index + 1}`}
                                            sx={{ width: '100%', height: '100%' }}
                                        />
                                    </Card>
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                </Box>
                <Box className={classes.productDetails}>
                    <Typography variant="h4" sx={{ fontFamily: "'Playfair Display', serif", my: 2, color: '#e4eac8' }}>Yellow Floral Delight Kurti Set</Typography>
                    <Typography sx={{ my: 2 }}>
                        <Box
                            component="span"
                            sx={{
                                color: 'grey.500',
                                fontFamily: "'Playfair Display', serif",
                                fontSize: '1rem',
                                marginRight: 1
                            }}
                        >
                            MRP 
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                textDecoration: 'line-through',
                                color: 'grey.500',
                                fontFamily: "'Playfair Display', serif",
                                fontSize: '1rem',
                                marginRight: 1
                            }}
                        >
                            ₹1,499
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                fontWeight: 'bold',
                                color: '#e4eac8',
                                fontFamily: "'Playfair Display', serif",
                                fontSize: '1.5rem'
                            }}
                        >
                            ₹1,195
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                color: '#b7ce58',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                marginLeft: 1
                            }}
                        >
                            (Rs. 304 OFF)
                        </Box>
                    </Typography>
                    {/* <Typography variant="body1" sx={{fontFamily: "'Playfair Display', serif", }}>Color: {color.charAt(0).toUpperCase() + color.slice(1)}</Typography>
                        <ToggleButtonGroup
                            value={color}
                            exclusive
                            onChange={(event, newColor) => setColor(newColor)}
                            aria-label="color selection"
                        >
                            <ToggleButton value="yellow">Yellow</ToggleButton>
                            <ToggleButton value="blue">Blue</ToggleButton>
                            <ToggleButton value="red">Red</ToggleButton>
                        </ToggleButtonGroup> */}
                    <Typography
                variant="h6"
                sx={{
                    mb: 2,
                    fontFamily: "'Playfair Display', serif",
                    fontWeight: 'bold',
                    color: '#e4eac8',
                    display: 'inline-flex',  // Use inline-flex to keep items on the same line
                    alignItems: 'center',
                    width: 'auto', // Allow width to adjust based on content
                }}
            >
                SIZE : {size}
                <IconButton
                    sx={{
                        ml: 2, // Margin to the left of the icon
                        minWidth: 40,  // Keep a fixed width for the icon button
                        display: 'flex', // Ensure the icon stays aligned
                    }}
                    onClick={handleClickOpen}
                    size="large"
                >
                    <InfoIcon sx={{ fontSize: '1.5rem', color: '#e4eac8' }} />
                </IconButton>
            </Typography>

            <Dialog open={open} onClose={handleClose}>
                <DialogContent sx={{ textAlign: 'center' }}>
                    <img
                        src={sizeChart}
                        alt="Size Chart"
                        style={{ maxWidth: '100%', maxHeight: '400px' }}
                    />
                </DialogContent>
            </Dialog>
                    <ToggleButtonGroup
                    value={size}
                    exclusive
                    onChange={(event, newSize) => setSize(newSize)}
                    aria-label="size selection"
                    sx={{
                        display: 'flex',
                        gap: 2, // Fixed gap between buttons
                    }}
                    >
                    {['S', 'M', 'L', 'XL', 'XXL'].map((label) => (
                        <ToggleButton
                        key={label}
                        value={label}
                        disableRipple // Disable ripple effects
                        disableTouchRipple // Disable touch ripple effects
                        sx={{
                            width: 50, // Makes buttons square
                            height: 50, // Matches width to ensure square
                            borderRadius: '50px !important', // Fully rounded edges override default styles
                            // border: '1px solid #e4eac8', // Default border
                            color: '#e4eac8', // Default text color
                            '&.Mui-selected': {
                            border: '2px solid #b7ce58', // Highlight border when selected
                            color: '#e4eac8', // Keep text color consistent
                            },
                            // '&:hover': {
                            // borderColor: '#b7ce58', // Optional: Border highlight on hover
                            // },
                        }}
                        >
                        {label}
                        </ToggleButton>
                    ))}
                    </ToggleButtonGroup>

                    <br></br>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            fontFamily: "'Playfair Display', serif",
                            fontWeight: 'bold',
                            color: '#e4eac8',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2, // Fixed gap between the text and box
                        }}
                    >
                        QUANTITY
                        <div></div>
                        {/* <br></br> */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 50, // Ensures the box is square
                                height: 50, // Keeps it square
                                backgroundColor: '#043430', // Background color
                                borderRadius: '50px', // Rounded box
                                color: '#e4eac8', // Text color
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                gap: 1,
                            }}
                        >
                            <Button
                                onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#e4eac8',
                                    fontSize: '1.5rem',
                                    minWidth: 'auto',
                                    padding: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    mr:1
                                }}
                            >
                                −
                            </Button>
                            <Typography variant="h6"
                            sx={{
                                my: 2,
                                fontFamily: "'Playfair Display', serif",
                                fontWeight: 'bold',
                                color: '#e4eac8',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2, // Fixed gap between the text and box
                            }}>{quantity}</Typography>
                            <Button
                                onClick={() => setQuantity((prev) => prev + 1)}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#e4eac8',
                                    fontSize: '1.5rem',
                                    minWidth: 'auto',
                                    padding: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ml:1
                                }}
                            >
                                +
                            </Button>
                        </Box>
                    </Typography>

                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBuyNow}
                    sx={{
                        mt: 1,
                        backgroundColor: '#e4eac8',
                        borderRadius: '20px', // Adjust border radius as needed
                        color: '#043430',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: "'Playfair Display', serif",
                        paddingLeft: 2, // Adjust spacing between icon and text
                    }}
                    >
                    <ShoppingCartIcon sx={{ marginRight: 1 }} /> {/* Icon before text */}
                    Buy Now
                    </Button>
                    <Typography
                        sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: 'center',
                            color: '#e4eac8',
                            fontWeight: 'bold',
                            fontFamily: "'Playfair Display', serif",
                        }}
                    >
                        <StarIcon sx={{ fontSize: '1rem', color: '#e4eac8', marginRight: 1 }} />
                        Delivered b/w 8-9 working days
                    </Typography>

                    <Typography
                        sx={{
                            mt: 1,
                            display: 'flex',
                            alignItems: 'center',
                            color: '#e4eac8',
                            fontSize: '0.8rem',
                            fontFamily: "'Playfair Display', serif",
                        }}
                    >
                        Exchange Policy
                        <IconButton
                            onClick={handleClickOpenReturn}
                            size="small"
                            sx={{ color: '#e4eac8', marginLeft: 1 }}
                        >
                            <ArrowForwardIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                    <Typography
                        sx={{
                            mt: 0.5,
                            color: '#e4eac8',
                            fontSize: '0.7rem',
                            fontFamily: "'Playfair Display', serif",
                        }}
                    >
                        (Customers must ship the product back to us for exchanges)
                    </Typography>

                    <Dialog open={openReturn} onClose={handleCloseReturn}>
                        <DialogContent sx={{ textAlign: 'center' }}>
                            <img
                                src={returnImage}
                                alt="Exchange Policy"
                                style={{ maxWidth: '100%', maxHeight: '800px' }}
                            />
                        </DialogContent>
                    </Dialog>
                </Box>
                {/* <Divider /> */}
                <Box
                    sx={{
                        border: '2px solid #e4eac8',
                        borderRadius: '16px',
                        padding: 2,
                        color: '#e4eac8',
                        my: 2
                    }}
                >
                    {/* Fabric and Pattern Section */}
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: '#e4eac8',
                            fontFamily: "'Playfair Display', serif",
                        }}
                        
                    >
                        <span>Fabric</span>
                        <span>Pattern</span>
                    </Typography>
                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', fontFamily: "'Playfair Display', serif", }}>
                        <span>Fabric Cotton</span>
                        <span> Printed</span>
                    </Typography>

                    {/* Product Details Section */}
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            mt: 2,
                            color: '#e4eac8',
                            fontFamily: "'Playfair Display', serif",
                        }}
                    >
                        Product Details
                    </Typography>
                    <ul style={{ marginLeft: -20, color: '#e4eac8', fontFamily: "'Playfair Display', serif", }}>
                        <li>Crafted from pure cotton</li>
                        <li>Elegant yellow floral patterns on kurti</li>
                        <li>Convenient pocket pants</li>
                        <li>Everyday wear</li>
                    </ul>

                    {/* One-liner */}
                    <Typography
                        sx={{
                            mt: 2,
                            fontStyle: 'italic',
                            color: '#e4eac8',
                            fontFamily: "'Playfair Display', serif",
                        }}
                    >
                        Embrace comfort and style with this vibrant, floral set
                    </Typography>

                    {/* Model Information */}
                    <Typography
                        sx={{
                            mt: 2,
                            color: '#e4eac8',
                            fontFamily: "'Playfair Display', serif",
                        }}
                    >
                        The model (height 5'4) is wearing a size S
                    </Typography>
                </Box>
                <Box
                    component="footer"
                    sx={{
                        textAlign: 'center',
                        mt: 4,
                        py: 2,
                        borderTop: '1px solid #e4eac8',
                        color: '#e4eac8',
                        fontSize: '0.875rem',
                        fontFamily: "'Playfair Display', serif",
                    }}
                >
                    <Box sx={{ mb: 2, fontFamily: "'Playfair Display', serif", fontSize: '1rem' }}>
                        FOLLOW US
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon sx={{ color: '#e4eac8', fontSize: '1.5rem' }} />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon sx={{ color: '#e4eac8', fontSize: '1.5rem' }} />
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                            <YouTubeIcon sx={{ color: '#e4eac8', fontSize: '1.5rem' }} />
                        </a>
                    </Box>
                    © 2024 Thalir. All rights reserved.
                </Box>
            </Container>}
        </Box>
    );
};

export default HDNewPricing;
